import React, { useEffect } from "react"
import { Link } from "react-router-dom"

export default function Header() {
  useEffect(() => {
    if (!localStorage.getItem("referrer")) {
      const params = new URLSearchParams(window.location.search)
      const via = params.get("via")
      const ref = params.get("ref")
      const httpReferrer = document.referrer

      if (via) {
        localStorage.setItem("referrer", via)
      } else if (ref) {
        localStorage.setItem("referrer", ref)
      } else if (httpReferrer) {
        localStorage.setItem("referrer", httpReferrer)
      }
    }
  }, [])

  return (
    <div className="py-4 w-full h-16 flex justify-between text-black border-b border-neutral-200 bg-white sticky top-0 left-0 px-3 md:px-6">
      <div className="flex items-center">
        <Link to="/" className="flex flex-col items-start">
          <span className="text-2xl font-bold">Parents Dir</span>
          <span className="text-sm text-gray-600">
            Find fun things to do with your kids
          </span>
        </Link>
      </div>
    </div>
  )
}
