import React, { useState, useMemo, useEffect } from "react"
import { useAuth } from "../../context/AuthContext"
import MapView from "./MapView"
import Table from "./Grid"
import FilterBar from "./FilterBar"

export default function Dashboard() {
  const { activities } = useAuth()

  const [filters, setFilters] = useState({
    city: "",
    category: "",
    age: "",
  })

  const [mapCenter, setMapCenter] = useState({ lat: 35.2433, lng: 38.9637 })
  const [mapZoom, setMapZoom] = useState(1.5)

  const [hoveredActivity, setHoveredActivity] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const activitiesPerPage = 16

  const calculateRelevance = (activity) => {
    let relevance = 0
    if (activity.photo_links && activity.photo_links[0] !== null) {
      relevance += 1
    }
    relevance += activity.rating || 0
    relevance += Math.min(activity.user_ratings_total / 10, 1) || 0
    return relevance
  }

  const filteredActivities = useMemo(() => {
    if (!activities) return []
    return activities
      .filter((activity) => {
        if (!activity.is_for_kids) return false
        if (
          filters.city &&
          activity.city_name.toLowerCase() !== filters.city.toLowerCase()
        )
          return false
        if (
          filters.category &&
          activity.category.toLowerCase() !== filters.category.toLowerCase()
        )
          return false
        if (filters.age) {
          const selectedAge = parseInt(filters.age)
          if (
            selectedAge < activity.age_range_from ||
            selectedAge > activity.age_range_to
          )
            return false
        }
        return true
      })
      .sort((a, b) => calculateRelevance(b) - calculateRelevance(a))
  }, [activities, filters])

  const totalPages = useMemo(() => {
    return Math.ceil((filteredActivities.length || 0) / activitiesPerPage)
  }, [filteredActivities, activitiesPerPage])

  const paginatedActivities = useMemo(() => {
    const startIndex = (currentPage - 1) * activitiesPerPage
    return filteredActivities.slice(startIndex, startIndex + activitiesPerPage)
  }, [filteredActivities, currentPage, activitiesPerPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters)
    setCurrentPage(1) // Reset to first page when filters change
  }

  const handleActivityHover = (activity) => {
    setHoveredActivity(activity)
  }

  const handleActivityLeave = () => {
    setHoveredActivity(null)
  }

  useEffect(() => {
    if (filters.city && filters.city.length > 0) {
      const selectedCity = activities?.find(
        (activity) =>
          activity.city_name.toLowerCase() === filters.city.toLowerCase()
      )
      if (selectedCity) {
        setMapCenter({
          lat: selectedCity.location.lat,
          lng: selectedCity.location.lng,
        })
        setMapZoom(11)
      }
    } else {
      setMapCenter({ lat: 35.2433, lng: 38.9637 })
      setMapZoom(1.5)
    }
  }, [filters.city, activities])

  return (
    <div className="flex flex-col">
      <FilterBar filters={filters} onFilterChange={handleFilterChange} />
      <div
        className="flex-grow flex flex-col md:flex-row-reverse relative"
        style={{ overflow: "visible" }}
      >
        <div className="w-full md:w-5/12 h-[calc(50vh-64px)] md:h-[calc(100vh-128px)]">
          <MapView
            activities={filteredActivities}
            filters={filters}
            center={mapCenter}
            zoom={mapZoom}
            hoveredActivity={hoveredActivity}
          />
        </div>
        <div className="w-full md:w-7/12 h-[calc(50vh-64px)] md:h-[calc(100vh-128px)] overflow-y-auto overflow-x-hidden md:overflow-x-hidden">
          <Table
            activities={paginatedActivities}
            onActivityHover={handleActivityHover}
            onActivityLeave={handleActivityLeave}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalActivities={filteredActivities.length}
            itemsPerPage={activitiesPerPage}
            city={filters.city || null} // Pass null if no city filter is applied
          />
        </div>
      </div>
    </div>
  )
}
