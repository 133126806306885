import React, { useState } from "react"
import { Link } from "react-router-dom"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import categories from "../assets/categories"

const ActivityCard = ({ activity, onHover, onLeave }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const getRatingBackgroundColor = (rating) => {
    if (rating >= 4.5) return "bg-green-500"
    if (rating >= 4) return "bg-lime-500"
    if (rating >= 3.5) return "bg-yellow-500"
    if (rating >= 3) return "bg-orange-500"
    if (rating === 0) return "bg-gray-500"
    return "bg-red-500"
  }

  const nextImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % activity.photo_links.length
    )
  }

  const prevImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + activity.photo_links.length) %
        activity.photo_links.length
    )
  }

  const categoryEmoji =
    categories.find(
      (cat) => cat.name.toLowerCase() === activity.category.toLowerCase()
    )?.emoji || "❓"

  return (
    <div
      className="bg-white overflow-hidden"
      onMouseEnter={() => onHover(activity)}
      onMouseLeave={onLeave}
    >
      <Link to={`/activity/${activity.unique_name}`} className="block">
        <div className="relative pb-[100%] rounded-lg">
          {activity.photo_links && activity.photo_links.length > 0 ? (
            <>
              <img
                src={activity.photo_links[currentImageIndex]}
                alt={activity.name}
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
              />
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                <FaChevronRight />
              </button>
            </>
          ) : (
            <div className="absolute inset-0 bg-gray-200 flex items-center justify-center rounded-t-lg">
              <span className="text-gray-500">No image available</span>
            </div>
          )}
        </div>
        <div className="pt-2">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold text-md">{activity.name}</h3>
            <div
              className={`p-1 rounded-xl w-16 h-8 flex items-center justify-center text-white text-lg font-bold transition duration-300 transform scale-100"
          } ${getRatingBackgroundColor(activity.rating)}`}
            >
              <span className="mr-1">{categoryEmoji}</span>
              {activity.rating?.toFixed(1)}
            </div>
          </div>
          <p className="text-gray-600 text-sm">{activity.category}</p>
          <p className="text-gray-600 text-sm">{activity.address}</p>
          <p className="text-gray-600 text-sm mb-1">
            {activity.international_phone_number}
          </p>
          {/* {activity.age_range_from && activity.age_range_to && (
          <p className="text-gray-600 text-sm mb-1">
            Age Range: {activity.age_range_from} - {activity.age_range_to}
          </p>
        )} */}
          {activity.website && (
            <p className="text-sm">
              <a
                href={activity.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Website
              </a>
            </p>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ActivityCard
