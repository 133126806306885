const categories = [
  { name: "Playground", emoji: "🎡" },
  { name: "Museum", emoji: "🖼️" },
  { name: "Zoo", emoji: "🦁" },
  { name: "Amusement Park", emoji: "🎢" },
  { name: "Water Park", emoji: "🌊" },
  { name: "Sports Facility", emoji: "⚽" },
  { name: "Arts and Crafts", emoji: "🎨" },
  { name: "Nature and Outdoors", emoji: "🌿" },
  { name: "Educational Center", emoji: "🎓" },
  { name: "Theater", emoji: "🎭" },
  { name: "Indoor Play Area", emoji: "🧩" },
  { name: "Trampoline Park", emoji: "🤸" },
  { name: "Climbing Center", emoji: "🧗" },
  { name: "Skating Rink", emoji: "⛸️" },
  { name: "Bowling Alley", emoji: "🎳" },
  { name: "Arcade", emoji: "🕹️" },
  { name: "Farm / Petting Zoo", emoji: "🐷" },
  { name: "Cinema", emoji: "🎥" },
  { name: "Science Center", emoji: "🔬" },
  { name: "Aquarium", emoji: "🐠" },
  { name: "Laser Tag Arena", emoji: "🔫" },
  { name: "Escape Room", emoji: "🔐" },
  { name: "Mini Golf", emoji: "⛳" },
  { name: "Go-Kart", emoji: "🏎️" },
  { name: "Cooking Class", emoji: "👨‍🍳" },
  { name: "Music School", emoji: "🎵" },
  { name: "Dance Studio", emoji: "💃" },
  { name: "Skate Park", emoji: "🛹" },
  { name: "Park", emoji: "🌳" },
  { name: "Swimming Pool", emoji: "🏊‍♂️" },
  { name: "Beach", emoji: "🏖️" },
  { name: "Thermal Bath", emoji: "🛁" },
  { name: "Other", emoji: "❓" },
]

export default categories
