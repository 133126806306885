import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore"
import {
  FaStar,
  FaClock,
  FaPhone,
  FaGlobe,
  FaMapMarkerAlt,
  FaChild,
  FaCalendarAlt,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa"
import MapView from "./Landingpage/MapView"

function ActivityDetail() {
  const { uniqueName } = useParams()
  const [activity, setActivity] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const fetchActivity = async () => {
      const db = getFirestore()
      const activitiesRef = collection(db, "activities")
      const q = query(activitiesRef, where("unique_name", "==", uniqueName))
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        setActivity(querySnapshot.docs[0].data())
      } else {
        console.log("No such activity!")
      }
      setLoading(false)
    }

    fetchActivity()
  }, [uniqueName])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    )
  }

  if (!activity) {
    return (
      <div className="flex justify-center items-center h-screen">
        Activity not found
      </div>
    )
  }

  const photoLinks = activity.photo_links || []
  const hasPhotos = photoLinks.length > 0

  const renderOpeningHours = () => {
    if (!activity.opening_hours || !activity.opening_hours.weekday_text) {
      return <p className="text-sm">Opening hours not available</p>
    }
    return activity.opening_hours.weekday_text.map((day, index) => (
      <p key={index} className="text-sm">
        {day}
      </p>
    ))
  }

  const nextImage = () => {
    if (hasPhotos) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === photoLinks.length - 1 ? 0 : prevIndex + 1
      )
    }
  }

  const prevImage = () => {
    if (hasPhotos) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? photoLinks.length - 1 : prevIndex - 1
      )
    }
  }

  const getCityFromAddress = (address) => {
    const parts = address.split(",")
    return parts[parts.length - 2].trim()
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="flex items-center mb-4">
        <h1 className="text-2xl font-semibold mr-4">
          {activity.name}
          {activity.name_orig && (
            <span className="text-sm text-gray-500 ml-2">
              ({activity.name_orig})
            </span>
          )}
        </h1>
        <div className="flex items-center">
          <FaStar className="text-yellow-400 mr-2" />
          <span className="font-bold mr-2">{activity?.rating}</span>
          <span className="text-gray-600">
            ({activity.user_ratings_total} reviews)
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        <div className="md:col-span-2">
          {hasPhotos ? (
            <div className="relative">
              <img
                src={photoLinks[currentImageIndex]}
                alt={`${activity.name} ${currentImageIndex + 1}`}
                className="w-full h-96 object-cover rounded-lg mb-4"
              />
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                <FaChevronRight />
              </button>
            </div>
          ) : (
            <div className="w-full h-96 bg-gray-200 flex items-center justify-center rounded-lg mb-4">
              <span className="text-gray-500">No photos available</span>
            </div>
          )}
          <div className="grid grid-cols-5 gap-2">
            {photoLinks.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`${activity.name} ${index + 1}`}
                className={`w-full h-24 object-cover rounded-lg cursor-pointer ${
                  index === currentImageIndex ? "border-2 border-blue-500" : ""
                }`}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Quick Info</h2>
          <div className="space-y-4">
            <div className="flex items-center">
              <FaMapMarkerAlt className="mr-3 text-blue-500" />
              <span className="text-gray-600">
                {getCityFromAddress(activity.address)}
              </span>
            </div>
            <div className="flex items-center">
              <FaChild className="mr-3 text-blue-500" />
              <span>
                For children aged {activity.age_range_from} to{" "}
                {activity.age_range_to} years
              </span>
            </div>
            <div className="flex items-center">
              <FaCalendarAlt className="mr-3 text-blue-500" />
              <span>{activity.category}</span>
            </div>
            <div>
              <h3 className="font-semibold mb-2 flex items-center">
                <FaClock className="mr-3 text-blue-500" />
                Opening Hours:
              </h3>
              {renderOpeningHours()}
            </div>
            <div className="flex items-center">
              <FaPhone className="mr-3 text-blue-500" />
              <span>{activity.international_phone_number}</span>
            </div>
            <div className="flex items-center">
              <FaGlobe className="mr-3 text-blue-500" />
              <a
                href={activity.website}
                className="text-blue-600 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Website
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">About {activity.name}</h2>
        <p className="mb-4">
          {activity.name} is a {activity.category} for children aged{" "}
          {activity.age_range_from} to {activity.age_range_to} years.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="font-semibold mb-2">Pros:</h3>
            <ul className="list-disc list-inside">
              {activity?.pros_cons?.pros?.map((pro, index) => (
                <li key={index}>{pro}</li>
              ))}
            </ul>
          </div>

          {activity?.pros_cons?.cons?.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2">Cons:</h3>
              <ul className="list-disc list-inside">
                {activity?.pros_cons?.cons?.map((con, index) => (
                  <li key={index}>{con}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Location</h2>
        <p className="mb-4">{activity.address}</p>
        <div className="h-96">
          <MapView
            activities={[activity]}
            filters={{}}
            center={activity.location}
            zoom={14}
          />
        </div>
      </div>
    </div>
  )
}

export default ActivityDetail
