import React from "react"
import "./assets/App.css"
import Header from "./pages/Landingpage/Header"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import Dashboard from "./pages/Landingpage/Dashboard"
import { Helmet, HelmetProvider } from "react-helmet-async"
import ActivityDetail from "./pages/ActivityDetail" // Neue Komponente importieren

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen ">
            <Header />
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/">
                <Helmet>
                  <title>
                    Activity Directory - Find the best activities in your area
                  </title>
                  <meta
                    name="description"
                    content="Find the best activities in your area with Activity Directory. Discover new hobbies, sports, and interests, all in one convenient location."
                  />
                  {/* <link rel="canonical" href="https://clarityai.co/" /> */}
                </Helmet>
                <Dashboard />
                {/* <Footer /> */}
              </Route>
              <Route path="/activity/:uniqueName">
                <ActivityDetail />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default App
